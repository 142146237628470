import type {MetaFunction} from "@remix-run/node";
import getAppLinks from "~/components/app-links/app-links";
import {useLoaderData} from "@remix-run/react";
import Landing3 from "~/components/landing-pages/landing-3/landing-3";


export const loader = async () => {
    return getAppLinks();
};



export const meta: MetaFunction = () => {
    return [
        {title: "The Bullish Trade"},
        {
            property: "og:title",
            content: "The Bullish Trade",
        },
        {
            property: "twitter:title",
            content: "The Bullish Trade",
        },
        {
            name: "description",
            content: "Options trader's secret toolkit",
        },
        {
            property: "og:type",
            content: "website",
        },
        {
            property: "og:url",
            content: "https://thebullish.trade",
        },
        {
            property: "og:description",
            content: "Options trader's secret toolkit",
        },
        {
            property: "twitter:description",
            content: "Options trader's secret toolkit",
        },
        {
            property: "og:image",
            content: "https://thebullish.trade/graphics/1024.jpg",
        },
        {
            property: "twitter:image",
            content: "https://thebullish.trade/graphics/1024.jpg",
        },
        {
            property: "twitter:card",
            content: "summary_large_image",
        },
        {
            property: "twitter:url",
            content: "https://thebullish.trade",
        },
        {
            property: "facebook-domain-verification",
            content:"uagqns8pcsavd3bcgmqema2u1iw4s4",
        },
        {
            name: "facebook-domain-verification",
            content:"uagqns8pcsavd3bcgmqema2u1iw4s4",
        },
        {
            tagName: "link",
            rel: "canonical",
            href: "https://thebullish.trade",
        },
    ];
};

export default function Index() {
    const {downloadLinks} = useLoaderData<typeof loader>();
    // return <h1>HELLO</h1>
    return (<Landing3 downloadLinks={downloadLinks}/>);
}
